<template>
  <a-divider orientation="left" class="mt-4">Documentation</a-divider>
  <a-row class="cards-box" :gutter="[16, 32]">
    <a-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
      class="center"
      v-for="item in portalConfig"
      :key="item.alt"
    >
      <div v-if="item.type === 'tutorial'" class="item-box center">
        <!-- <div class="h-100"> -->
        <div class="video-box">
          <iframe
            width="100%"
            height="100%"
            :src="item.link"
            allowfullscreen
            webkitAllowFullScreen
            mozAllowfullscreen
          >
          </iframe>
        </div>
        <div class="p-4 flex-fill">
          <h5>{{ item.title }}</h5>
          <p>
            {{ item.message }}
          </p>
        </div>
        <!-- </div> -->
      </div>
      <div v-else class="item-box doc-box center p-4">
        <h5>{{ item.title }}</h5>
        <p>
          {{ item.message }}
        </p>

        <a-button type="primary" :href="item.link">
          <template #icon>
            <DownloadOutlined />
          </template>
          Download
        </a-button>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import question from './questions.json';
import { portalConfig } from './config';
import { DownloadOutlined, YoutubeOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    DownloadOutlined,
    YoutubeOutlined,
  },
  data() {
    return {
      list: question.list,
      portalConfig: portalConfig.filter((item) => !item.isOutDated),
      source: '',
    };
  },
  methods: {
    handleChange(e) {
      const file = e.target.files[0];
      this.source = '';
    },
    clickDownload(link) {
      window.open(link, '_blank');
    },
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
i {
  font-size: 40px;
}

p {
  font-family: 'Montserrat', sans-serif;
  margin: 1.5em 0;
}

a {
  text-decoration: none;
}

span {
  font-weight: 600;
}

.cards-box {
  max-width: 1200px;
  padding: 2em 3em;
  margin: 0 auto !important;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-box {
  flex-direction: column;
  height: 520px;
  max-width: 385px;
  background: #fff;
  text-align: center;
  box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px 12px;
}

.doc-box {
  width: 385px;
}

.video-box {
  width: 100%;
  height: 250px;
}

@media only screen and (max-width: 576px) {
  .cards-box {
    padding: 0;
  }
}
</style>
